import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby';
import fetch from 'isomorphic-fetch';
import ls from 'local-storage'
import './Instagram.scss'
import Carousel from '../Carousel';
import { FaCircleNotch } from 'react-icons/fa'

class TheInstagramFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ls.get('instagram') ? ls.get('instagram') : null ,
      loaded: false,
      error: null
    }
  }

  componentDidMount() {
    const { options } = this.props
    const { instagramName = 'birdbraindigital' } = options
    var currentDt = new Date();
    var updateDt = new Date();
    updateDt.setHours( updateDt.getHours() + 4 );
    const { data } = this.state
    const storeDate = data && data.time ? data.time : ''
    const compareDate = new Date(storeDate)
    if ( !data || data.time && compareDate < currentDt ) {
      //console.log(`https://www.instagram.com/${instagramName}/?__a=1`)
      fetch(`${process.env.GATSBY_WP_ADDRESS}/wp-json/instagram/v2/feed`)
        .then(response => response.json())
        .then( data => {
        if (data) {
          const dataFeed = { time: updateDt,  feed: data}
          ls.set('instagram',dataFeed)
          this.setState({
              data: dataFeed,
              loaded: true
          })
        }
        //console.log('Instagram Updated')
      })
      .catch(error => {
        console.log('error:',error)
        this.setState({
          error: error
        })
      })
    } else {
      this.setState({loaded: true})
      //console.log('Load Instagram Data')
    }
  }

  render() {
    const { data, loaded, error } = this.state
    const { numberOfMediaElements = 12, options, title = `<em>Follow</em> us on Instagram` } = this.props
    const { instagramName = 'birdbraindigital' } = options
    const settings = {
      container: 'instagram-carousel',
      nav: false,
      mouseDrag: true,
      controls: false,
      loop: false,
      gutter: 0,
      slideBy: 1,
      autoplay: true,
      rewind: true,
      speed: 2500,
      autoplayTimeout: 2500,
      responsive: {
        1600: {
          items: 6,
        },
        1024: {
          items: 4,
        },
        640: {
          items: 3,
        },
        420: {
          items: 2,
        },
        320: {
          items: 2,
        }
      }
    };

    return (
      <section className="instafeed">
        <div className="title">
          <h3 className="section-title"><a href={`https://www.instagram.com/${instagramName}/`} target="_blank">{title}</a></h3>
        </div>
        {error && <div className="error"><div className="inner">Error</div></div>}
        {!loaded && <div className="loading"><div className="inner"><FaCircleNotch/></div></div>}
        {loaded && data && data.feed &&
        <Carousel settings={settings}>
          {data.feed.map((item, index) => numberOfMediaElements > index && (
            <div className="slide" key={index}><a href={`https://www.instagram.com/p/${item.node.shortcode}`} target="_blank" rel="noopener"><img src={item.node.thumbnail_resources[3].src}/></a></div>
          ))}
          <div className="slide"><div className="more"><a href={`https://www.instagram.com/${instagramName}/`} target="_blank" rel="noopener" className="action">More</a></div></div>
        </Carousel>
        }
      </section>
    )
  }
}


export default props => (
  <StaticQuery
    query={graphql`
      query {
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            instagramFeed
            instagramName
          }
        }
      }
    `}
    render={data => data.siteSettings.options.instagramFeed ? <TheInstagramFeed options={data.siteSettings.options} {...props} /> : null}
  />
)
